import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'msg',
    path: '/msg/:rid/:msg',
    component: () => import('./view/msg.vue'),
  },
  {
    name: 'apply',
    path: '/apply/:key',
    component: () => import('./view/apply.vue'),
  },
  {
    name: 'result',
    path: '/result/:key',
    component: () => import('./view/result.vue'),
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

// router.beforeEach((to, from, next) => {
//   // let title = to.meta && to.meta.title;
//   // document.title = title || '电子发票';
//   next();
// });

export { router };
